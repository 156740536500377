import { Box, Flex, Tabs, TabList, TabPanels, Tab, TabPanel, TabIndicator, Grid, useDisclosure } from '@chakra-ui/react'
import { Button } from 'components'
import UserSelectable from './UserSelectable'
import GroupSelectable from './GroupSelectable'
import { UserOrGuest, FlowGuest as Guest } from 'features/workflow/components/FlowModal/types/flow-form-types'
import { UserGroupModal } from 'pages/Admin/UserManagement/components'
import { useState } from 'react'
import { UserGroup } from 'pages/Admin/hooks/useAdmin'
import { User } from 'types/graphqlSchema'
import { scrollbar } from 'theme'

interface props {
  userSelectOptions: UserOrGuest[]
  selectedOptions: UserOrGuest[]
  toggleSelectUser: (emailAddress: string) => void
  toggleSelectGroup: (groupId: string) => void
  guestValue: Guest | null
  handleAddGuest: () => void
  userGroups: UserGroup[]
  setUserGroups: React.Dispatch<React.SetStateAction<UserGroup[]>>
  users: User[]
  selectedGroups: UserGroup[]
}

const UsersAndGroupsSelect = ({
  userSelectOptions,
  selectedOptions,
  toggleSelectUser,
  toggleSelectGroup,
  guestValue,
  handleAddGuest,
  userGroups,
  setUserGroups,
  users,
  selectedGroups,
}: props) => {
  const { onOpen, onClose, isOpen } = useDisclosure()
  const [groupForModal, setGroupForModal] = useState<UserGroup | null>(null)

  const handleClickEditGroup = (group: UserGroup) => {
    setGroupForModal(group)
    onOpen()
  }

  return (
    <>
      <Flex
        pt={4}
        flexDir="column"
        gap={0}
        h="full"
        roundedBottom={6}
        border="1px solid var(--chakra-colors-borderLight)"
      >
        <Tabs variant="unstyled" h="full" maxH="300px" pb="1rem" flex={1} flexDir="column" roundedBottom={6}>
          <TabList
            bg="textHighlight"
            color="textBlack"
            _hover={{ color: 'textBlack' }}
            px={4}
            fontSize="14px"
            fontWeight={400}
          >
            <Tab
              color="inherit"
              _hover={{ color: 'inherit' }}
              _selected={{ color: 'inherit', fontWeight: 500 }}
              fontSize="14px"
              fontWeight={400}
            >
              All <span style={{ fontSize: '.75rem', marginLeft: '.75rem' }}>{userSelectOptions?.length}</span>
            </Tab>
            <Tab
              color="inherit"
              _hover={{ color: 'inherit' }}
              _selected={{ color: 'inherit', fontWeight: 500 }}
              fontSize="14px"
              fontWeight={400}
            >
              Groups <span style={{ fontSize: '.75rem', marginLeft: '.75rem' }}>{userGroups?.length}</span>
            </Tab>
          </TabList>
          <TabIndicator mt="-1.5px" height="2px" bg="textBlack" borderRadius="1px" width="43px !important" ml="14px" />
          <TabPanels mt="1rem" h="full">
            <TabPanel
              p={0}
              overflowY="scroll"
              maxH="200px"
              sx={{
                ...scrollbar,
                scrollbarColor: 'var(--chakra-colors-textRegular) var(--chakra-colors-textHighlight)',
              }}
            >
              {guestValue && (
                <Grid w="full" placeContent="center">
                  <Button
                    onClick={handleAddGuest}
                    variant="defaultButton"
                    mb={4}
                    label={`Add guest: ${guestValue.email}?`}
                  />
                </Grid>
              )}
              {userSelectOptions.length > 0 &&
                userSelectOptions.map((option) => {
                  const selected = selectedOptions?.some((sel) => sel.email === option.email)
                  return (
                    <Box
                      m={0}
                      onClick={() => {
                        toggleSelectUser(option.email)
                      }}
                    >
                      <UserSelectable data={option} selected={selected} />
                    </Box>
                  )
                })}
            </TabPanel>
            <TabPanel
              p={0}
              minH="200px"
              display="flex"
              flexDir="column"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Flex w="full" flex={1} flexDir="column" justifyContent="space-between">
                <Flex
                  flexDir="column"
                  maxH="230px"
                  overflowY="auto"
                  css={{
                    '&::-webkit-scrollbar': {
                      width: '0',
                    },
                    '&::-webkit-scrollbar-track': {
                      width: '0',
                    },
                  }}
                >
                  {userGroups.length > 0 &&
                    userGroups.map((group) => {
                      const selected = selectedGroups?.some((sel) => sel.id === group.id)
                      return (
                        <Box
                          m={0}
                          onClick={(e) => {
                            e.stopPropagation()
                            toggleSelectGroup(group.id)
                          }}
                        >
                          <GroupSelectable
                            group={group}
                            selected={selected}
                            setUserGroups={setUserGroups}
                            editFunction={handleClickEditGroup}
                            toggleSelectGroup={toggleSelectGroup}
                          />
                        </Box>
                      )
                    })}
                </Flex>
                <Button
                  onClick={onOpen}
                  color="textRegular"
                  mt="auto"
                  fontWeight={400}
                  fontSize=".75rem"
                  label="Add new group"
                  alignSelf="flex-start"
                />
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
      <UserGroupModal
        isOpen={isOpen}
        onClose={onClose}
        setUserGroups={setUserGroups}
        users={users}
        group={groupForModal || undefined}
      />
    </>
  )
}

export default UsersAndGroupsSelect
