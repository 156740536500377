import { Flex, Button } from '@chakra-ui/react'
import { AvatarsTooltip } from 'components'

const ShareTaskFormFooter = ({ submitDisabled, submit, owner, isSubmitting }) => {
  return (
    <Flex mt="auto" as="footer" w="full" justify="space-between" minH="3rem" px={6}>
      {owner && (
        <Flex align="center" gap={6}>
          <AvatarsTooltip users={[owner]} />
        </Flex>
      )}
      <Button
        isDisabled={submitDisabled}
        onClick={submit}
        isLoading={isSubmitting}
        variant={'defaultButton'}
        _disabled={{
          bg: 'var(--chakra-colors-textSoft)',
          color: 'var(--chakra-colors-textRegular)',
          border: '1px solid transparent',
          _hover: {
            backgroundColor: 'var(--chakra-colors-textSoft)',
            color: 'var(--chakra-colors-textRegular)',
            border: '1px solid textBlack',
            cursor: 'not-allowed',
          },
        }}
        fontWeight={500}
      >
        Share
      </Button>
    </Flex>
  )
}

export default ShareTaskFormFooter
