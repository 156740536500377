import { useSubscription, useQuery } from '@apollo/client'
import { FLOWS_SUBSCRIPTION, GET_FLOWS } from 'features/graphql'
import { Flow } from 'types/graphqlSchema'

const useTasks = (variables) => {
  const {
    data: flowsData,
    refetch,
    loading: flowsLoading,
    error: flowsError,
  } = useQuery<{ flows: Flow[] }>(GET_FLOWS, {
    variables,
    pollInterval: 120000,
  })
  useSubscription(FLOWS_SUBSCRIPTION, {
    variables,
    onData: () => {
      refetch()
    },
    onError: (error) => {
      console.error(error.message)
    },
  })

  const hasFlows = flowsData && flowsData?.flows?.length > 0

  return {
    flowsData: flowsData?.flows?.filter((flow) => flow.node?.id === variables.nodeId) || [],
    refetch,
    flowsLoading,
    flowsError,
    hasFlows,
  }
}

export default useTasks
