import { Flex, Icon, chakra, useCheckbox } from '@chakra-ui/react'

const CheckIcon = () => {
  return (
    <>
      <Icon width="11" height="11" viewBox="0 0 11 11" fill="none">
        <path
          d="M1 7.35294L4.54375 10L9.1 1"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </Icon>
    </>
  )
}

const Checkbox = (props) => {
  const { state, getCheckboxProps, getInputProps, getLabelProps, htmlProps } = useCheckbox(props)

  return (
    <chakra.label display="flex" flexDirection="row" alignItems="center" cursor="pointer" {...htmlProps}>
      <input {...getInputProps()} hidden />
      <Flex
        alignItems="center"
        justifyContent="center"
        border="2px solid"
        borderColor="textRegular"
        rounded="lg"
        w={4}
        h={4}
        _checked={{
          bg: 'accent',
          color: 'white',
          borderColor: 'accent',
        }}
        _disabled={{
          borderColor: 'textRegular',
          bg: 'textSoft',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        {...getCheckboxProps()}
      >
        {state.isChecked && <CheckIcon />}
      </Flex>
    </chakra.label>
  )
}

export default Checkbox
