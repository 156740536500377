import React, { useState, useEffect, useMemo } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useAppSlice, useBoxSlice, useProjectSlice, useTipSlice, useWorkflowSlice } from 'features/redux'
import { Flex, Text, Box, Grid, GridItem, Image, Tooltip } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { uniqBy } from 'lodash'
import { workflowConstant } from 'helpers/constant'
import { useFlowActions } from 'features/workflow/hooks'
import { USERS, GET_USER_GUEST_SHARE_MEMBERS } from 'features/graphql'
import { AvatarsTooltip, Checkbox, DueDatePopover, Toast, UsersAndGroupsSelect, UsersSelectInput } from 'components'
import { Flow, Maybe, Node, NodeType, User, UserGroup } from 'types/graphqlSchema'
import { FlowModalToggle } from './components'
import { regs } from 'helpers/vali'
import { useFlowForm } from './hooks/useFlowForm'
import { UserOrGuest, FlowGuest as Guest } from './types/flow-form-types'
import { useAdmin } from 'pages/Admin/hooks'
import ShareTaskFormFooter from './components/ShareTaskFormFooter'
import { useNodeDetails, useNodeMenuActions } from 'features/node'
import ShareTaskFormMessage from './components/ShareTaskFormOptions'
import { images, scrollbar } from 'theme'
import { isFirefox } from 'react-device-detect'

interface props {
  flowType: string
  node: Node
  flowState: FlowState
  setFlowState: React.Dispatch<React.SetStateAction<FlowState>>
  handleNewFlowOnNode?: () => void
  flowsData?: Flow[]
}

// Type Check Helpers
const isUser = (user: UserOrGuest): user is User => 'id' in user
const isValidUser = (user: Maybe<User>): user is User => user !== null && user !== undefined && 'id' in user

const ShareTaskForm = ({ node, flowState, setFlowState, flowType, handleNewFlowOnNode, flowsData }: props) => {
  // Users
  const { user } = useAppSlice()
  const [fetchUsers] = useLazyQuery(USERS)
  const [fetchGuests] = useLazyQuery(GET_USER_GUEST_SHARE_MEMBERS)

  // Groups
  const { userGroups, setUserGroups } = useAdmin()
  const [recipientGroups, setRecipientGroups] = useState<UserGroup[]>([])
  const [groupSelectOptions, setGroupSelectOptions] = useState<UserGroup[]>([])

  // Tasks
  const { workflows } = useWorkflowSlice()
  const { createFlow, startFlow, removeUserFromFlow } = useFlowActions()
  const [activeTaskTypes, setActiveTaskTypes] = useState({
    approval: false,
    file: false,
    feedback: false,
  })
  const hasActiveTask = useMemo(() => {
    return Object.keys(activeTaskTypes).some((key) => activeTaskTypes[key])
  }, [activeTaskTypes])

  // Sharing
  const { shareNode, unshareNode, removeGroupFromNode } = useNodeMenuActions()
  const { dispatch, updateTip } = useTipSlice()
  const { updateBox } = useBoxSlice()
  const { updateProject } = useProjectSlice()

  // Task Participants
  const [recipientsInputValue, setRecipientsInputValue] = useState('')
  const [usersData, setUsersData] = useState<User[]>()
  const [userSelectOptions, setUserSelectOptions] = useState<UserOrGuest[]>([])

  const { members, sharedGroups } = useNodeDetails(node)
  const nonGroupMembers = useMemo(() => {
    return members.filter((m) => !sharedGroups.some((g) => g.users?.some((u) => u?.id === m.id)))
  }, [members, sharedGroups])

  const [recipients, setRecipients] = useState<UserOrGuest[]>([])
  const [approvalRecipients, setApprovalRecipients] = useState<string[]>([])
  const [feedbackRecipients, setFeedbackRecipients] = useState<string[]>([])
  const [fileRecipients, setFileRecipients] = useState<string[]>([])
  const recipientEmails = useMemo(() => [...recipients, ...members].map((r) => r.email), [recipients, members])

  const { isMobile, setIsSubmitting, usersSelectOpen, onUsersSelectOpen, onUsersSelectClose, isSubmitting } =
    useFlowForm({
      recipientGroupsLength: recipientGroups.length,
      flowRecipientEmails: recipientEmails,
      flowState: {
        due: flowState.due,
        name: flowState.name,
      },
      attachedProject: node.project ?? undefined,
    })

  // Add Guests
  const [newGuest, setNewGuest] = useState<Guest | null>(null)
  const [createdGuests, setCreatedGuests] = useState<Guest[]>([])

  // Effects and Memos 10

  const modalHeight = useMemo(() => {
    const baseHeight = node.type === NodeType.Tip ? 440 : 360

    let heightAddition = 0

    if (recipientGroups.length > 0 && recipientGroups.length <= 3) heightAddition += 100
    if (recipientGroups.length > 3 && recipientGroups.length <= 6) heightAddition += 180

    if (recipientEmails.length > 0 && recipientEmails.length <= 3) heightAddition += 40
    if (recipientEmails.length > 3 && recipientEmails.length <= 6) heightAddition += 60

    if (hasActiveTask) heightAddition += 160

    return baseHeight + heightAddition
  }, [recipientEmails?.length, isMobile, hasActiveTask])

  // Initialize recipient options
  useEffect(() => {
    const initRecipientOptions = async () => {
      const [usersResult, guestUsersResult] = await Promise.all([fetchUsers(), fetchGuests()])
      const orgUsers = usersResult.data.users
      const guestUsers = guestUsersResult.data?.me?.shareEmailSuggestions || []
      // filtering out current user
      const potentialFlowMembers = uniqBy([...orgUsers, ...guestUsers], 'id')
        .filter((u) => u.email !== user.email)
        .filter((u) => !members.some((m) => m.email === u.email))
      const userDataWithEmailValue = potentialFlowMembers.map((user) => ({
        ...user,
        value: user.email,
        label: user.firstName + ' ' + user.lastName,
      }))
      setGroupSelectOptions(userGroups)
      setUsersData(potentialFlowMembers)
      setUserSelectOptions(userDataWithEmailValue)
    }
    initRecipientOptions()
  }, [userGroups, members])

  // EFFECT -
  useEffect(() => {
    filterRecipientOptions()
    filterGroupOptions()
  }, [usersData, createdGuests])

  // Memo
  const selectedRecipients = useMemo(() => {
    return recipients
  }, [recipients])

  const selectedGroups = useMemo(() => {
    return recipientGroups
  }, [recipientGroups])

  // Setters 1

  const filterRecipientOptions = () => {
    if (!usersData || usersData.length === 0) return

    const userDataWithEmailValue = usersData.map((user) => ({
      ...user,
      value: user.email,
      label: user.firstName + ' ' + user.lastName,
    }))

    const options = createdGuests.length > 0 ? [...createdGuests, ...userDataWithEmailValue] : userDataWithEmailValue

    let filteredOptions = options

    if (recipientsInputValue.length > 1) {
      filteredOptions = filteredOptions.filter((option) => {
        const fullName = `${option.firstName} ${option.lastName}`.toLowerCase()
        return (
          option.firstName.toLowerCase().startsWith(recipientsInputValue.toLowerCase()) ||
          option.lastName.toLowerCase().startsWith(recipientsInputValue.toLowerCase()) ||
          fullName.startsWith(recipientsInputValue.toLowerCase()) ||
          option.email.toLowerCase().startsWith(recipientsInputValue.toLowerCase())
        )
      })
    } else {
      filteredOptions = options
    }

    const filteredList = filteredOptions
    setUserSelectOptions(filteredList)
  }

  const filterGroupOptions = () => {
    if (!userGroups || userGroups.length === 0) return

    let filteredOptions = userGroups
    if (recipientsInputValue.length > 1) {
      filteredOptions = filteredOptions.filter((option) => {
        if (!option.users || option.users.length === 0) return false
        return option.users.some((user) => {
          const isUser = (user: Maybe<User>): user is User => {
            if (!user) return false
            return 'id' in user
          }
          if (!isUser(user)) return false
          const fullName = `${user.firstName} ${user.lastName}`.toLowerCase()
          return (
            user.firstName.toLowerCase().startsWith(recipientsInputValue.toLowerCase()) ||
            user.lastName.toLowerCase().startsWith(recipientsInputValue.toLowerCase()) ||
            fullName.startsWith(recipientsInputValue.toLowerCase()) ||
            user.email.toLowerCase().startsWith(recipientsInputValue.toLowerCase())
          )
        })
      })
    }

    const filteredList = filteredOptions
    setGroupSelectOptions(filteredList)
  }

  // HANDLERS

  const handleInputChange = (value: string) => {
    setRecipientsInputValue(value)

    const isEmail = regs.email.test.test(value)
    const isMyEmail = user?.email === value

    filterGroupOptions()
    filterRecipientOptions()

    if (!isEmail || isMyEmail) {
      setNewGuest(null)
      return
    }

    // Extract and capitalize name parts
    const capitalize = (part: string) => part.charAt(0).toUpperCase() + part.slice(1)

    const [firstName, rest] = value.split('@')
    const [lastName] = rest.split('.')

    const guest = {
      email: value,
      firstName: capitalize(firstName),
      lastName: capitalize(lastName),
      organization: { name: 'Guest' },
    }

    setNewGuest(guest)
  }

  const handleAddGuest = () => {
    if (newGuest) {
      setCreatedGuests((prev) => {
        if (prev) {
          return [newGuest, ...prev]
        } else {
          return [newGuest]
        }
      })
      filterRecipientOptions()
      setRecipientsInputValue('')
      setNewGuest(null)
    }
  }

  const toggleSelectUser = (emailAddress: string) => {
    if (!emailAddress) return
    const user = userSelectOptions.find((u) => u.email === emailAddress)
    if (!user) return

    const selected = recipients.some((sel) => sel.email === user.email)
    if (selected) {
      const updatedList = recipients.filter((r) => r.email !== user.email)
      setRecipients(updatedList)
    } else {
      setRecipients((prev) => [...prev, user])
    }
  }

  const toggleSelectGroup = (groupId: string) => {
    const group = userGroups.find((g) => g.id === groupId)
    if (!group) return

    const selected = selectedGroups?.some((sel) => sel.id === groupId)
    if (selected) {
      setRecipientGroups((prev) => prev.filter((g) => g.id !== groupId))
    } else {
      setRecipientGroups((prev) => [...prev, group])
    }
  }

  const handleMessageChange = (e) => {
    let inputValue = e.target.value
    const maxLength = 250

    if (inputValue.length > maxLength) {
      inputValue = inputValue.substring(0, maxLength)
    }

    setFlowState((state) => ({
      ...state,
      message: inputValue,
    }))
  }

  const handleShareSent = async () => {
    if (!node) return
    if (!recipients && recipientGroups?.length === 0) {
      return
    }
    const alreadySharedEmails = node.sharedMembers?.map((sm) => sm?.sharedWith?.email) || []
    const recipientEmails = recipients.filter((r) => !alreadySharedEmails.includes(r.email))
    const recipientGroupEmails = recipientGroups
      .map((g) => g?.users?.map((u) => u?.email))
      .flat()
      .filter((e) => e !== user.email)
    const newEmails = recipientEmails.map((u) => u.email)
    const uniqueEmails = [...new Set([...newEmails, ...recipientGroupEmails])].join(', ')

    const shareResponse = await shareNode(uniqueEmails, node.id, '', recipientGroups.map((g) => g.id) ?? [''])

    if (shareResponse?.id) {
      setRecipientGroups([])
      setRecipients([])
      Toast.show({
        icon: 'check',
        message: 'Recipients Added',
      })
      if (node.type === 'BOX') {
        dispatch(updateBox({ id: node.id }))
      }
      dispatch(updateTip({ id: node.id }))
      dispatch(updateProject({ id: node.projectId }))
      return shareResponse.id
    } else {
      // setShareState((prev) => ({ ...prev, sentState: "Error - Couldn't Share" }))
    }
  }

  const handleShareAndTasks = async () => {
    setIsSubmitting(true)
    await handleShareSent()

    if (!hasActiveTask) {
      setIsSubmitting(false)
      return
    }

    const approvalId = workflows?.find((wf) => wf?.name === workflowConstant.approval.name)?.id
    const feedbackId = workflows?.find((wf) => wf?.name === workflowConstant.feedback.name)?.id

    let flowCreated = false

    if (approvalId && activeTaskTypes.approval && approvalRecipients.length > 0) {
      try {
        const startingFlow = await createFlow({
          name: node.name + '-' + crypto.randomUUID().slice(0, 4),
          projectId: node.projectId,
          organizationId: node.organizationId,
          nodeId: node.id,
        })

        try {
          const { flowId } = await startFlow(workflowConstant.approval.name, {
            flowId: startingFlow?.id,
            dueDate: flowState.due,
            urgent: flowState.urgent,
            uploadNeed: flowState.uploadNeed,
            requesterMessage: flowState.message,
            projectId: node.projectId,
            workflowId: approvalId,
            participantsEmails: approvalRecipients,
            remindersFrequency: flowState.reminder.selected ? flowState.reminder.interval : 0,
          })

          if (flowId) {
            flowCreated = true
            Toast.show({
              icon: 'check',
              message: `Approval task created.`,
            })
          }
        } catch (error) {
          console.error('An error occurred while starting an approval flow:', error)
        }
      } catch (error) {
        console.error(error.message, error)
      }
    }

    if (feedbackId && activeTaskTypes.feedback && feedbackRecipients.length > 0) {
      try {
        const startingFlow = await createFlow({
          name: node.name + '-' + crypto.randomUUID().slice(0, 4),
          projectId: node.projectId,
          organizationId: node.organizationId,
          nodeId: node.id,
        })

        try {
          const { flowId } = await startFlow(workflowConstant.feedback.name, {
            flowId: startingFlow?.id,
            dueDate: flowState.due,
            urgent: flowState.urgent,
            uploadNeed: false,
            requesterMessage: flowState.message,
            projectId: node.projectId,
            workflowId: feedbackId,
            participantsEmails: feedbackRecipients,
            remindersFrequency: flowState.reminder.selected ? flowState.reminder.interval : 0,
          })

          if (flowId) {
            flowCreated = true
            Toast.show({
              icon: 'check',
              message: `Feedback task created.`,
            })
          }
        } catch (error) {
          console.error('An error occurred while starting a feedback flow:', error)
        }
      } catch (error) {
        console.error(error.message, error)
      }
    }

    if (feedbackId && activeTaskTypes.file && fileRecipients.length > 0) {
      try {
        const startingFlow = await createFlow({
          name: node.name + '-' + crypto.randomUUID().slice(0, 4),
          projectId: node.projectId,
          organizationId: node.organizationId,
          nodeId: node.id,
        })

        try {
          const { flowId } = await startFlow(workflowConstant.feedback.name, {
            flowId: startingFlow?.id,
            dueDate: flowState.due,
            urgent: flowState.urgent,
            uploadNeed: true,
            requesterMessage: flowState.message,
            projectId: node.projectId,
            workflowId: feedbackId,
            participantsEmails: fileRecipients,
            remindersFrequency: flowState.reminder.selected ? flowState.reminder.interval : 0,
          })

          if (flowId) {
            flowCreated = true
            Toast.show({
              icon: 'check',
              message: `File request task created.`,
            })
          }
        } catch (error) {
          console.error('An error occurred while starting a file request flow:', error)
        }
      } catch (error) {
        console.error(error.message, error)
      }
    }

    if (flowCreated && handleNewFlowOnNode) {
      handleNewFlowOnNode()
    }

    setIsSubmitting(false)
  }

  const recipientSetters = {
    approval: setApprovalRecipients,
    feedback: setFeedbackRecipients,
    file: setFileRecipients,
  }

  const handleRecipientChange = (recipientType, e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value
    const isChecked = e.target.checked
    const setRecipients = recipientSetters[recipientType]

    setRecipients((prev: string[]) => (isChecked ? [...prev, email] : prev.filter((r) => r !== email)))
  }

  const handleGroupRecipientChange = (recipientType, e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked
    const group = [...recipientGroups, ...sharedGroups].find((g) => g.id === e.target.value)

    if (!group?.users?.length) return

    const emailAddresses: string[] = group.users
      .map((u) => u?.email)
      .filter((email): email is string => email !== undefined && email !== null)

    const setRecipients = recipientSetters[recipientType]

    setRecipients((prev: string[]) =>
      isChecked ? [...prev, ...emailAddresses] : prev.filter((r) => !emailAddresses.includes(r)),
    )
  }

  const handleApproverChange = (e: React.ChangeEvent<HTMLInputElement>) => handleRecipientChange('approval', e)

  const handleGroupApproverChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleGroupRecipientChange('approval', e)

  const handleFeedbackChange = (e: React.ChangeEvent<HTMLInputElement>) => handleRecipientChange('feedback', e)

  const handleGroupFeedbackChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleGroupRecipientChange('feedback', e)

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => handleRecipientChange('file', e)

  const handleGroupFileChange = (e: React.ChangeEvent<HTMLInputElement>) => handleGroupRecipientChange('file', e)

  const submitButtonDisabled = () => {
    return (
      isSubmitting ||
      (recipients.length === 0 &&
        recipientGroups.length === 0 &&
        !approvalRecipients.length &&
        !feedbackRecipients.length &&
        !fileRecipients.length)
    )
  }

  const unshareGroupUsers = async (userGroup: UserGroup, nodeId: string): Promise<void> => {
    if (!userGroup.users?.length) return

    // type check users and exclude current user
    const validGroupUsers = userGroup.users.filter(isValidUser).filter((u) => u.id !== user.id)

    const usersInOtherGroups = sharedGroups
      .filter((group) => group.id !== userGroup.id) // Exclude current group
      .flatMap((group) => group.users || [])
      .filter(isValidUser)
      .map((u) => u.id)

    // Find users that only exist in this group
    // so overlapping users from other groups will remain shared
    const uniqueUsersToThisGroup = validGroupUsers.filter((u) => !usersInOtherGroups.includes(u.id))

    for (const u of uniqueUsersToThisGroup) {
      try {
        if (flowsData) {
          await removePendingFlows(u, flowsData)
        }
        await unshareNode(u.id, nodeId)
      } catch (error) {
        console.error('Failed to remove user:', error)
      }
    }
  }

  const removePendingFlows = async (user: User, flowsData: Flow[]): Promise<void> => {
    const pendingFlowsWithUser = flowsData.filter(
      (f) => f?.state?.stateName === 'Pending' && f?.participants?.some((p) => p?.id === user.id),
    )

    if (pendingFlowsWithUser.length > 0) {
      await Promise.all(
        pendingFlowsWithUser
          .filter((f): f is Flow & { id: string } => f !== null && f !== undefined && typeof f.id === 'string')
          .map((f) => removeUserFromFlow(f.id, user.id)),
      )
    }
  }

  const handleUnshare = async (user?: UserOrGuest, userGroup?: UserGroup) => {
    if (userGroup) {
      const isPending = !sharedGroups.some((g) => g.id === userGroup.id)

      if (isPending) {
        setRecipientGroups((prev) => prev.filter((g) => g.id !== userGroup.id))
      } else {
        if (userGroup.users?.length) {
          await unshareGroupUsers(userGroup, node.id)
          await removeGroupFromNode(node.id, userGroup.id)
          dispatch(updateTip({ id: node.id }))
        }
      }
      return
    }

    if (user) {
      const isPending = !members.some((m) => m.email === user.email)
      if (isPending) {
        setRecipients((prev) => prev.filter((r) => r.email !== user.email))
        return
      }
      if (!isUser(user)) return
      try {
        // Handle pending flows
        if (flowsData) {
          await removePendingFlows(user, flowsData)
        }
        await unshareNode(user.id, node.id)
      } catch (error) {
        console.error('Failed to remove user:', error)
      }
    }
  }

  return (
    <motion.div
      layout
      key={flowType}
      aria-label={flowType + ' Flow'}
      initial={{ height: 30, opacity: 0 }}
      animate={{
        height: modalHeight,
        opacity: 1,
      }}
      transition={{
        type: 'ease',
        duration: 0.6,
        opacity: { delay: 0.6 },
      }}
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: isMobile ? '.5rem' : '1rem',
        width: '100%',
        fontSize: isMobile ? '12px' : '14px',
        overflow: 'hidden',
      }}
    >
      <Text pl={6} fontSize="14px" color="textBlack" fontWeight={500}>
        Share:
      </Text>
      <Flex px={6} flexDir="column">
        <Box
          w="full"
          border="1px solid var(--chakra-colors-borderLight)"
          roundedTop={6}
          roundedBottom={usersSelectOpen ? 0 : 6}
          borderBottom={usersSelectOpen ? '1px solid transparent' : '1px solid var(--chakra-colors-borderLight)'}
        >
          <UsersSelectInput
            value={recipientsInputValue}
            onChange={handleInputChange}
            isOpen={usersSelectOpen}
            onOpen={onUsersSelectOpen}
            onClose={onUsersSelectClose}
          />
        </Box>
        {usersSelectOpen && (
          <UsersAndGroupsSelect
            userSelectOptions={userSelectOptions}
            selectedOptions={selectedRecipients}
            selectedGroups={selectedGroups}
            toggleSelectUser={toggleSelectUser}
            toggleSelectGroup={toggleSelectGroup}
            guestValue={newGuest}
            handleAddGuest={handleAddGuest}
            userGroups={groupSelectOptions}
            setUserGroups={setUserGroups}
            users={usersData ?? []}
          />
        )}
      </Flex>

      {!usersSelectOpen && (
        <>
          <Grid templateColumns={`280px 1fr 1fr 1fr ${isFirefox ? '0px' : '12px'}`} pl={6} mb="-4px">
            {hasActiveTask && ([...recipients, ...members].length > 0 || recipientGroups.length > 0) && (
              <>
                <GridItem colSpan={1}></GridItem>
                <GridItem colSpan={1}>
                  {activeTaskTypes.approval && (
                    <Text textAlign="center" fontWeight={400} color="textBlack" fontSize="14px">
                      Approval
                    </Text>
                  )}
                </GridItem>
                <GridItem colSpan={1}>
                  {activeTaskTypes.file && (
                    <Text textAlign="center" fontWeight={400} color="textBlack" fontSize="14px">
                      File
                    </Text>
                  )}
                </GridItem>
                <GridItem colSpan={1}>
                  {activeTaskTypes.feedback && (
                    <Text textAlign="center" fontWeight={400} color="textBlack" fontSize="14px">
                      Feedback
                    </Text>
                  )}
                </GridItem>
                <GridItem colSpan={1}></GridItem>
              </>
            )}
          </Grid>
          <Grid
            templateColumns="280px 1fr 1fr 1fr"
            overflowY="scroll"
            sx={{
              ...scrollbar,
              scrollbarColor: 'var(--chakra-colors-textRegular) var(--chakra-colors-textHighlight)',
            }}
            pl={6}
          >
            {[...recipientGroups, ...sharedGroups].map((group) => (
              <React.Fragment key={group.id}>
                <GridItem display="flex" gap={4} alignItems="center">
                  <Tooltip
                    hasArrow
                    fontSize={'13px'}
                    rounded="lg"
                    px=".75rem"
                    py=".75rem"
                    placement="top"
                    bg="textBlack"
                    label={
                      <Flex flexDir={'column'}>
                        <Text p={0} m={0} color={'textHighlight'}>{`Remove ${group.name}`}</Text>
                      </Flex>
                    }
                  >
                    <button
                      style={{
                        border: '1px solid var(--chakra-colors-borderRegular)',
                        padding: '0.25rem',
                        borderRadius: '6px',
                      }}
                      onClick={() => {
                        handleUnshare(undefined, group)
                      }}
                    >
                      <Image p={0} m={0} src={images.xSmall} />
                    </button>
                  </Tooltip>
                  <Flex align="center" gap={2}>
                    <Grid bg="accentIcon" rounded="full" h="2rem" w="2rem" placeContent="center">
                      <Text fontWeight={400} color="textBlack">
                        + {group.users?.length ?? 0}
                      </Text>
                    </Grid>
                    <Flex flexDir="column">
                      <Text fontSize={14} fontWeight={400} color="tipbox.dark.text.charcoalGrey">
                        {group.name}
                      </Text>
                      <Text fontSize={12} color="tipbox.dark.text.charcoalGrey">
                        {group.users?.length ?? 0} members
                      </Text>
                    </Flex>
                  </Flex>
                  {!sharedGroups?.some((g) => g.id === group.id) && (
                    <Text ml="auto" color="textRegular" fontSize={12}>
                      (pending)
                    </Text>
                  )}
                </GridItem>
                <GridItem display="flex" justifyContent="center" alignItems="center">
                  {activeTaskTypes.approval && (
                    <Checkbox variant="tipbox" value={group.id} onChange={handleGroupApproverChange} />
                  )}
                </GridItem>
                <GridItem display="flex" justifyContent="center" alignItems="center">
                  {activeTaskTypes.file && (
                    <Checkbox variant="tipbox" value={group.id} onChange={handleGroupFileChange} />
                  )}
                </GridItem>
                <GridItem display="flex" justifyContent="center" alignItems="center">
                  {activeTaskTypes.feedback && (
                    <Checkbox variant="tipbox" value={group.id} onChange={handleGroupFeedbackChange} />
                  )}
                </GridItem>
              </React.Fragment>
            ))}
            {[...recipients, ...nonGroupMembers].map((r) => (
              <React.Fragment key={r.email}>
                <GridItem display="flex" gap={4} alignItems="center">
                  <Tooltip
                    hasArrow
                    fontSize={'13px'}
                    rounded="lg"
                    px=".75rem"
                    py=".75rem"
                    placement="top"
                    bg="textBlack"
                    label={
                      <Flex flexDir={'column'}>
                        <Text p={0} m={0} color={'textHighlight'}>{`Remove ${r.firstName} ${r.lastName}`}</Text>
                      </Flex>
                    }
                  >
                    <button
                      style={{
                        border: '1px solid var(--chakra-colors-borderRegular)',
                        padding: '0.25rem',
                        borderRadius: '6px',
                      }}
                      onClick={() => handleUnshare(r)}
                    >
                      <Image p={0} m={0} src={images.xSmall} />
                    </button>
                  </Tooltip>
                  <AvatarsTooltip users={[r]} />
                  {!members?.some((mem) => mem.email === r.email) && (
                    <Text ml="auto" color="textRegular" fontSize={12}>
                      (pending)
                    </Text>
                  )}
                </GridItem>
                <GridItem display="flex" justifyContent="center" alignItems="center">
                  {activeTaskTypes.approval && (
                    <Checkbox variant="tipbox" value={r.email} onChange={handleApproverChange} />
                  )}
                </GridItem>
                <GridItem display="flex" justifyContent="center" alignItems="center">
                  {activeTaskTypes.file && <Checkbox variant="tipbox" value={r.email} onChange={handleFileChange} />}
                </GridItem>
                <GridItem display="flex" justifyContent="center" alignItems="center">
                  {activeTaskTypes.feedback && (
                    <Checkbox variant="tipbox" value={r.email} onChange={handleFeedbackChange} />
                  )}
                </GridItem>
              </React.Fragment>
            ))}
          </Grid>
          {node.type === NodeType.Tip && (
            <>
              <Text pl={6} fontSize="14px" color="textBlack" fontWeight={500}>
                Add a task (optional):
              </Text>
              <Flex w="full" gap={12} pl={6}>
                {Object.keys(activeTaskTypes).map((type) => (
                  <FlowModalToggle
                    key={type}
                    falseImage={images.flowToggleNegative}
                    trueImage={images.flowTogglePositive}
                    toggleItem={activeTaskTypes[type]}
                    onChange={() => {
                      setActiveTaskTypes((prev) => ({ ...prev, [type]: !prev[type] }))
                    }}
                  >
                    {type.charAt(0).toUpperCase() + type.slice(1)}
                  </FlowModalToggle>
                ))}
              </Flex>
            </>
          )}
          {hasActiveTask && (
            <>
              <Text pl={6} fontSize="14px" color="textBlack" fontWeight={500}>
                Due Date (optional):
              </Text>
              <Flex align="center" pl={6} gap={6}>
                <Flex>
                  <DueDatePopover flowState={flowState} onChange={(a) => setFlowState(a)} isRequired />
                </Flex>
                {/* Urgent */}
                <FlowModalToggle
                  falseImage={images.flowToggleExclamationNegative}
                  trueImage={images.flowToggleExclamation}
                  toggleItem={flowState.urgent}
                  onChange={() => {
                    setFlowState((prev) => ({
                      ...prev,
                      urgent: !prev.urgent,
                    }))
                  }}
                >
                  {flowState.urgent ? 'Urgent' : 'Mark Urgent'}
                </FlowModalToggle>
              </Flex>
            </>
          )}

          <ShareTaskFormMessage flowState={flowState} handleMessageChange={handleMessageChange} />
          <ShareTaskFormFooter
            owner={node?.owner}
            submitDisabled={submitButtonDisabled()}
            submit={handleShareAndTasks}
            isSubmitting={isSubmitting}
          />
        </>
      )}
    </motion.div>
  )
}

export default ShareTaskForm
