import React, { useState, useEffect } from 'react'
// import { useQuery } from '@apollo/client'
import { Draggable } from 'react-beautiful-dnd'
import { Box, Spinner, useDisclosure } from '@chakra-ui/react'

import { useClipboard } from 'hooks'
import { useTipRedirect } from 'features/tip/hooks'
import { useNodeMenuActions } from 'features/node/hooks'
import { useAppSlice, useProjectSlice, useTipSlice, useLinkShareSlice } from 'features/redux'
// import { NOTIFICATION_COMMENT_TIP } from 'modules/notification/notification.schema'
import { menuActions, menuTip, tipUpdateValues } from 'helpers/constant'
import { getResizedImage } from 'helpers/storage'
import CONFIG from 'helpers/config'
import { ModalExternalButton, UpdateModal, MoveToMenu, AlertWithAction, Toast } from 'components'
import { NodeDetailsInfo } from 'features/node/components'
import { TagsModal } from 'features/tags/components'
import { FlowModal, ShareTaskModal } from 'features/workflow'
import { EntityType, Node } from 'types/graphqlSchema'
import { TipGridCard, TipListCard } from './'
import { minDelay } from 'helpers/utils'
import useTasks from 'features/message/components/Comments/hooks/useTasks'

interface TipCardProps {
  tip: Node
  alerts: number
  index: number
  pageType?: string
  noBorder?: boolean
  shouldTipReorder?: boolean
  setDragEnterIndex?: React.Dispatch<React.SetStateAction<number | undefined>>
  setDraggingTip?: React.Dispatch<React.SetStateAction<string>>
  setDuplicatingTip: React.Dispatch<React.SetStateAction<string>>
  setDeletingTip: React.Dispatch<React.SetStateAction<string>>
  deletingTip: string
}

const TipCard = ({
  tip,
  index,
  pageType,
  noBorder,
  shouldTipReorder,
  setDragEnterIndex,
  setDraggingTip,
  setDuplicatingTip,
  deletingTip,
  setDeletingTip,
}: TipCardProps) => {
  const {
    viewType,
    user: { followedNodes },
    selectedNode,
    popupMenuTips,
    setPopupMenuTips,
  } = useAppSlice()
  const { dispatch, currentProject, getProject } = useProjectSlice()
  const { flowsData } = useTasks({
    key: tip.id,
    nodeId: tip.id,
  })

  const {
    isOpen: isOpenLeaveSharedNode,
    onOpen: onOpenLeaveSharedNode,
    onClose: onCloseLeaveSharedNode,
  } = useDisclosure()
  const { isOpen: isOpenDeleteNode, onOpen: onOpenDeleteNode, onClose: onCloseDeleteNode } = useDisclosure()

  const { tips, setTips } = useTipSlice()
  const { publicLink, getRandomPathNodeChild } = useLinkShareSlice()
  const { handleRedirectClick } = useTipRedirect()
  const { name, id, coverImage, coverImageSecureUrl } = tip
  const {
    openModal,
    setOpenModal,
    updateNode,
    deleteNode,
    updateFollowedNode,
    downloadNode,
    duplicateNode,
    handleLeaveSharedNode,
  } = useNodeMenuActions()

  const [menuOpen, setMenuOpen] = useState(false)
  const [signedCoverImage, setSignedCoverImage] = useState('')
  const { copyToClipBoard } = useClipboard()

  const handleDelete = async () => {
    setDeletingTip(tip.id)
    const allTips = tips.filter((t: Node) => t.id !== tip.id)

    try {
      await minDelay(deleteNode(tip), 400)
      Toast.show({
        icon: 'check',
        message: tip?.name + ' was moved to Trash.  You can restore it there.',
      })
      setDeletingTip('')
      dispatch(setTips(allTips))
    } catch (error) {
      setDeletingTip('')
      console.error(error)
    }
  }

  const handleUpdate = async (updatedValue: Partial<Node>) => {
    await updateNode(updatedValue, tip)
    setOpenModal('')
  }

  const linkRedirect = () => {
    if (publicLink.randomPath) {
      dispatch(
        getRandomPathNodeChild({
          randomPath: publicLink.randomPath,
          nodeId: id,
        }),
      )
    }
  }

  const handleCopyLinkShare = async () => {
    if (tip?.id) copyToClipBoard(`${CONFIG.APP.URL}/link/${tip.id}`)
  }

  const settingMenuClose = () => {
    const array = popupMenuTips?.map((item: IPupMenu) => ({
      itemId: item.itemId,
      isOpen: false,
    }))
    dispatch(setPopupMenuTips(array))
  }

  const handleDuplicate = async () => {
    setDuplicatingTip(id)
    await minDelay(duplicateNode(id, 'tip'), 1500)
    return await setDuplicatingTip('')
  }

  const handleMenuAction = async (action: string): Promise<void> => {
    setOpenModal(action)
    switch (action) {
      case menuActions.download:
        downloadNode(tip)
        setOpenModal('')
        settingMenuClose()
        break
      case menuActions.duplicate:
        handleDuplicate()
        break
      case menuActions.delete:
        setOpenModal('')
        onOpenDeleteNode()
        break
      case menuActions.follow:
        updateFollowedNode(id)
        break
      case menuActions.copyLink:
        handleCopyLinkShare()
        setOpenModal('')
        break
      case menuActions.unshare:
        setOpenModal('')
        onOpenLeaveSharedNode()
        break
      default:
        break
    }
    setMenuOpen(false)
  }

  const handleClick = () => {
    if (!currentProject) {
      const projectId = tip.project?.id || tip.projectId
      projectId && dispatch(getProject(projectId))
    }
    handleRedirectClick(tip, pageType)
  }

  const handleSingleTipClick = () => {
    if (pageType === 'publicLink') {
      linkRedirect()
    } else {
      handleClick()
    }
  }
  // SUBSCRIPTION

  useEffect(() => {
    if (!coverImageSecureUrl) return
    const resizedImage = getResizedImage(coverImageSecureUrl, 'tip.main')
    if (resizedImage) {
      setSignedCoverImage(resizedImage)
    }
  }, [coverImage, name, pageType])

  // Variables
  // * Renders TipGridCard OR TipListCard based on view type
  const tipsLayout =
    viewType === 'grid' ? (
      <Box pos="relative" width="100%" pb="100%" h={0}>
        <TipGridCard
          tip={{
            ...tip,
            coverImage: signedCoverImage,
          }}
          followedNodes={followedNodes}
          alerts={0}
          selected={id === selectedNode?.id}
          handleMenuAction={handleMenuAction}
          onSingleTipClick={handleSingleTipClick}
          pageType={pageType !== undefined ? menuTip[pageType] : menuTip.base}
          noBorder={noBorder}
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          isLinkShare={pageType === 'publicLink'}
          setDragEnterIndex={setDragEnterIndex}
          setDraggingTip={setDraggingTip}
          setOpenModal={setOpenModal}
        />
      </Box>
    ) : (
      <TipListCard
        tip={{
          ...tip,
          coverImage: signedCoverImage,
        }}
        followedNodes={followedNodes}
        alerts={0}
        selected={id === selectedNode?.id}
        handleMenuAction={handleMenuAction}
        onSingleTipClick={handleSingleTipClick}
        pageType={pageType !== undefined ? menuTip[pageType] : menuTip.base}
        noBorder={noBorder}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        setOpenModal={setOpenModal}
        isLinkShare={pageType === 'publicLink'}
      />
    )
  const tipDraggable = (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...provided.draggableProps.style,
            opacity: snapshot.isDragging ? 0.7 : 1,
            transform: shouldTipReorder ? provided.draggableProps.style?.transform : '',
            transition: shouldTipReorder ? provided.draggableProps.style?.transition : '',
          }}
        >
          {tipsLayout}
        </Box>
      )}
    </Draggable>
  )
  const tipNonDraggable = (
    <Box
    // onMouseEnter={() => {
    //   setCheck(true)
    // }}
    // onMouseLeave={() => {
    //   setCheck(true)
    // }}
    >
      {tipsLayout}
    </Box>
  )

  if (deletingTip === id) {
    return <Spinner placeSelf="center" thickness="4px" speed="0.65s" emptyColor="gray.200" color="accent" size="lg" />
  }

  return (
    <>
      {/** Disable dnd in Grid view only */}
      {viewType === 'grid' ? tipNonDraggable : tipDraggable}

      {/* DELETE File */}
      <AlertWithAction
        action={handleDelete}
        isOpen={isOpenDeleteNode}
        onClose={onCloseDeleteNode}
        actionText={`Delete the document "${tip.name}"?`}
        warningText={[`"${tip.name}" will be moved to the trash.`]}
        confirmButtonText="Delete"
      />

      {/* Move Model */}
      <ModalExternalButton
        header={`Move ${name}`}
        modelWidth="580px"
        modelHeight="723px"
        isOpen={openModal === 'moveTo'}
        close={() => {
          setOpenModal('')
        }}
      >
        <MoveToMenu
          closeAction={() => {
            setOpenModal('')
          }}
          movingResource={tip}
        />
      </ModalExternalButton>

      {/* Update name & Cover Image */}
      <ModalExternalButton
        header={tipUpdateValues[openModal]?.header}
        isOpen={openModal === menuActions.rename || openModal === menuActions.coverImage}
        close={() => {
          setOpenModal('')
        }}
      >
        <UpdateModal
          placeholder={
            openModal === menuActions.rename && !tip.name ? 'Untitled' : tipUpdateValues[openModal]?.placeholder
          }
          label={tipUpdateValues[openModal]?.label}
          name="tipCover"
          startValue={name}
          onCancel={() => {
            setOpenModal('')
          }}
          onUpdate={handleUpdate}
          isImage={tipUpdateValues[openModal]?.isImage}
          image={coverImage}
        />
      </ModalExternalButton>

      {/* MOBILE DETAILS */}
      <ModalExternalButton
        header="Tip Details"
        isOpen={openModal === menuActions.details}
        close={() => {
          setOpenModal('')
        }}
        size="full"
        modelWidth="full"
        rounded={0}
        headerBg="background"
        isCentered={false}
      >
        <NodeDetailsInfo node={tip} collapsed={false} />
      </ModalExternalButton>
      <TagsModal
        nodeType="Item"
        entityName={tip.name}
        entityId={tip.id}
        entityType={EntityType.Node}
        isOpen={openModal === menuActions.tag}
        onClose={() => setOpenModal('')}
      />

      <AlertWithAction
        action={() => {
          handleLeaveSharedNode(tip.id)
          const allTips = tips.filter((t: Node) => t.id !== tip.id)
          dispatch(setTips(allTips))
          onCloseLeaveSharedNode()
        }}
        isOpen={isOpenLeaveSharedNode}
        onClose={onCloseLeaveSharedNode}
        actionText="Remove Myself"
        warningText={['You will no longer have access to this document.']}
      />
      <ShareTaskModal
        node={tip}
        isOpen={openModal === menuActions.share}
        onClose={() => setOpenModal('')}
        handleNewFlowOnNode={() => setOpenModal('')}
        flowsData={flowsData}
      />
      <FlowModal node={tip} isOpen={openModal === menuActions.flow} onClose={() => setOpenModal('')} />
    </>
  )
}

export default TipCard
