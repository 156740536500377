import { customIcons } from 'theme'
import { GET_ORG, UPDATE_USER_ROLE, useAppSlice } from 'features/app'
import { PrivacySetting, UpdateOrganizationInput, User, UserGroup } from 'types/graphqlSchema'
import { MutationTuple, useLazyQuery, useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import { Toast } from 'components'
import { minDelay } from 'helpers/utils'

export type CreateUserGroupMutation = MutationTuple<
  { createUserGroup: UserGroup },
  { name: string; userIds: string[]; groupType: string }
>

export type UserRoles = {
  role: string
  userId: string
  userOrgId: string
}[]

export default function useAdmin() {
  const adminMenus = [
    {
      name: 'General',
      subtitle: 'General Settings',
      icon: customIcons.gearIcon,
      path: '/admin',
    },
    {
      name: 'Privacy & Auth.',
      subtitle: 'Privacy & Authentication Settings',
      icon: customIcons.privacyAuthIcon,
      path: '/admin/privacy-auth',
    },
    {
      name: 'User Management',
      subtitle: 'User Managerment Settings',
      icon: customIcons.userManagementIcon,
      path: '/admin/user-management',
    },
    {
      name: 'Projects',
      subtitle: 'Projects Management Settings',
      icon: customIcons.projectIcon,
      path: '/admin/projects',
    },
    {
      name: 'Billing',
      subtitle: 'Organization Billing Settings',
      icon: customIcons.creditCardIcon,
      path: '/admin/billing',
    },
    {
      name: 'Data Storage',
      subtitle: 'Organization Data Storage Settings',
      icon: customIcons.dataStorageIcon,
      path: '/admin/data-storage',
    },
  ]
  const {
    dispatch,
    updateMe,
    updateOrganization,
    user: {
      organizationId,
      organization: { privacySetting, name: orgNameFromDB },
    },
  } = useAppSlice()

  const [fetchOrgData, { data: orgData }] = useLazyQuery(GET_ORG, {
    variables: { organizationId },
    onError: (error) => {
      console.error(error)
    },
    onCompleted: () => {
      if (orgData) {
        setUserRoles(
          orgData.organization.users.map((user) => {
            return { role: user.role, userId: user.id, userOrgId: user.organizationId }
          }),
        )
        setOrgNameValue(orgData.organization.name)
        setPrivacyValue(orgData.organization.privacySetting)
        setUsers(orgData.organization?.users)
        setUserGroups(orgData.organization?.userGroups)
      }
    },
  })

  const [updateUserRole] = useMutation(UPDATE_USER_ROLE, {
    onCompleted: (data) => {
      if (data) {
        minDelay(Promise.resolve(), 750).then(() => {
          setIsSaving(false)
          Toast.show({
            icon: 'check',
            message: 'User role has been updated.',
          })
          fetchOrgData()
        })
      }
    },
    onError: (err) => {
      throw new Error(err.message)
    },
  })

  const [privacyValue, setPrivacyValue] = useState<PrivacySetting>(privacySetting)
  const [orgNameValue, setOrgNameValue] = useState<string>(orgNameFromDB)
  const [userGroups, setUserGroups] = useState<UserGroup[]>([])
  const [userRoles, setUserRoles] = useState<UserRoles>([])
  const [changedValues, setChangedValues] = useState<string[]>([])
  const [isSaving, setIsSaving] = useState(false)

  const [users, setUsers] = useState<User[]>([])

  const handleSaveChanges = async () => {
    if (changedValues.length === 0) return
    setIsSaving(true)

    try {
      if (changedValues.includes('userRoles')) {
        const changedRoles = userRoles.filter((userRole) => {
          const orgUser = orgData.organization.users.find((user) => user.id === userRole.userId)
          return orgUser && orgUser.role !== userRole.role
        })
        await Promise.all(changedRoles.map((role) => updateUserRole({ variables: role })))
      }

      if (changedValues.includes('privacyValue') || changedValues.includes('orgNameValue')) {
        const organizationInput: UpdateOrganizationInput = {
          id: organizationId,
          name: orgNameValue,
          privacySetting: privacyValue,
        }
        const res = await dispatch(updateOrganization(organizationInput))
        if (res.meta.requestStatus === 'fulfilled') {
          const newPrivacySetting =
            res.payload.privacySetting === privacySetting
              ? null
              : res.payload.privacySetting === 'INVITE_ONLY'
              ? 'Private'
              : 'Public'
          const newName = res.payload.name === orgNameFromDB ? null : res.payload.name
          newPrivacySetting &&
            Toast.show({
              icon: 'check',
              message: 'Default privacy setting updated to ' + newPrivacySetting + '.',
            })
          newName &&
            Toast.show({
              icon: 'check',
              message: 'Your organization has been renamed "' + newName + '".',
            })
          dispatch(updateMe({}))
          setIsSaving(false)
        }
        return true
        // get values into in redux user object ⬇️
      }
    } catch (error) {
      console.error('Error updating organization:', error)
    }
  }

  useEffect(() => {
    fetchOrgData()
  }, [])

  return {
    adminMenus,
    users,
    userGroups,
    userRoles,
    setUserRoles,
    privacyValue,
    setPrivacyValue,
    orgNameValue,
    setOrgNameValue,
    changedValues,
    setChangedValues,
    setUsers,
    setUserGroups,
    handleSaveChanges,
    isSaving,
    orgData,
    fetchOrgData,
  }
}
