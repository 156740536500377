import { Flex, Text } from '@chakra-ui/react'
import FlowFormMessage from './FlowFormMessage'

const ShareTaskFormMessage = ({ flowState, handleMessageChange }) => {
  return (
    <Flex mb="auto" flexDir={'column'} gap={'14px'}>
      <Text pl={6} fontSize="14px" color="textBlack" fontWeight={500}>
        Message (optional):
      </Text>
      <Flex flexDir={'column'} gap={'1.25rem'}>
        <FlowFormMessage value={flowState?.message} onChange={handleMessageChange} />
      </Flex>
    </Flex>
  )
}

export default ShareTaskFormMessage
